import { Injectable } from '@angular/core';
import { catchError, from, map, Observable, throwError } from 'rxjs';
import Butter from 'buttercms';

import { ButterCMSModel } from '@core/modals/butter-cms.model';
import { environment } from '@env/environment';
import { PorterService } from '@shared/services/porter/porter.service';
import { ButterCMSConstants } from '@core/constants/butter-cms.constant';
import { AcPorterComponent } from '@routing/ac-routing.enum';

export const butterService = Butter(environment.butterCmsAPIKey);

@Injectable({
  providedIn: 'root'
})
export class ButtercmsService {

  constructor(private porterService: PorterService) {}

  /**
   * Get Menus from Butter CMS
   * @returns Menu Items
   */
  getNavigationMenuItem(menuItem: string): Observable<ButterCMSModel.NavigationMenuItems[]> {
    return from(
      butterService.content.retrieve([menuItem], {
        levels: 3
      })
    ).pipe(
      map((resp: any) => resp.data.data.navigation_menu.filter((data: any) => data.name.includes('AC Menu'))[0].menu_items as ButterCMSModel.NavigationMenuItems[]),
      catchError((error) => throwError(() => new Error(`${error} - Error in ${error}`)))
    );
  }

  /**
   * Get Collection data from Butter CMS
   * @returns Collection Data
   */
  getCollectionContent(pageName: string) {
    return from(butterService.content.retrieve([pageName] as any, { levels: 3 })).pipe(
      map((resp: any) => resp.data.data),
      catchError((error) => throwError(() => new Error(`${error} - Error in ${error}`)))
    );
  }

  /**
   * Retrieve Page content (home, Loans, Savings, About us, FAQ, & Guides)
   * @returns Page content
   */
  getPageContent(pageName: string): Observable<any> {
   if (pageName !== ButterCMSConstants.SEO_METADATA) this.porterService.openModal(AcPorterComponent.Loader);
    return from(butterService.page.retrieve('*' as any, pageName)).pipe(
      map((resp: any) => {
        if (pageName !== ButterCMSConstants.SEO_METADATA) this.porterService.closeModal();
        return resp.data.data;
      }),
      catchError((error) => {
        return throwError(() => new Error(`${error} - Error in ${error}`));
      })
    );
  }

  /**
   * Get Legal blog post selected slug
   * @param slug The slug of the blog post to retrieve
   * @returns Blog post content
   */
  getBlogPage(slug: string): Observable<any> {
    this.porterService.openModal(AcPorterComponent.Loader);
    return from(butterService.post.retrieve(slug)).pipe(
      map((resp: any) => {
        this.porterService.closeModal();
        return resp.data.data
      }),
      catchError((error) => throwError(() => new Error(`${error} - Error in ${error}`)))
    );
  }
}
