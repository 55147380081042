<div *ngIf="handlerData.state">
  <ng-container [ngSwitch]="handlerData.component">
    <ng-container *ngSwitchCase="porterEnum.Loader">
      <ac-loader />
    </ng-container>
    <ng-container *ngSwitchCase="porterEnum.TeamsModal">
      <ac-modal [modalData]="handlerData"/>
    </ng-container>
    <ng-container *ngSwitchCase="porterEnum.OurValuesModal">
      <ac-modal [modalData]="handlerData"/>
    </ng-container>
  </ng-container>
</div>
