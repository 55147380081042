import { AfterViewInit, Directive, HostListener, TemplateRef, ViewContainerRef } from '@angular/core';
import { interval, take } from 'rxjs';

@Directive({
  selector: '[acLoadOnScroll]',
  standalone: true
})
export class LoadOnScrollDirective implements AfterViewInit {
  private isScrolling = false;
  private isScrollPossible !: boolean;

  constructor(private templateRef: TemplateRef<any>, private vcr: ViewContainerRef) { }

  @HostListener('window:scroll', [])
  onScroll(): void {
    if (!this.isScrolling && this.isScrollPossible) {
      this.isScrolling = true;
      this.vcr.createEmbeddedView(this.templateRef);
    }
  }

  ngAfterViewInit(): void {
    interval(100)
      .pipe(take(1)) // Adjust the number of emissions as needed
      .subscribe(() => {
        this.checkScrollingPossibility();
      });
  }

  private checkScrollingPossibility(): void {
    this.isScrollPossible = document.body.scrollHeight > window.innerHeight;

    if (!this.isScrollPossible) {
      this.vcr.createEmbeddedView(this.templateRef);
    }
  }
}
