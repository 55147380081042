<div (click)="closeModal()" *ngIf="modalData" class="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-5">
  <ng-container [ngSwitch]="modalData.component">
    <ng-container *ngSwitchCase="'teamsModal'">
      <div (click)="preventPropagation($event)" class="max-h-[85vh] w-full max-w-3xl overflow-y-auto rounded-sm bg-white transition-opacity duration-700 ease-in-out sm:w-[48rem]">
        <div class="relative grid grid-cols-12 gap-0 p-5 sm:gap-2 sm:p-0">
          <button (click)="closeModal()" class="absolute right-3 top-5 z-50 text-gray-600 hover:text-gray-800">&#9587;</button>
          <!-- Modal content goes here -->
          <div class="col-span-12 sm:col-span-6">
            <h3 class="mb-2 block border-none text-center font-libre font-normal no-underline sm:hidden">
              {{ modalData.data.name }}
            </h3>
            <img [attr.alt]="modalData.data.heading" acResponsiveImg [ngSrc]="modalData.data.background_image" class="h-60 w-full object-contain grayscale transition-all duration-500 ease-in-out hover:grayscale-0 sm:h-full sm:object-cover" height="400" width="400" />
          </div>
          <div class="col-span-12 p-2 sm:col-span-6 sm:p-4">
            <div [class.opacity-100]="modalData.state" class="py-1 opacity-0 transition-opacity duration-700 ease-in-out sm:py-3">
              <h3 class="hidden max-w-sm font-libre font-normal no-underline sm:block">
                {{ modalData.data.heading }}
              </h3>
              <p class="my-2 font-normal italic">
                {{ modalData.data.sub_heading }}
              </p>
              <div [innerHTML]="modalData.data?.paragraph_content | safeHtml" class="font-normal child:mb-5"></div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  
    <!-- For Our Values -->
    <ng-container *ngSwitchCase="'ourValuesModal'">
      <div (click)="preventPropagation($event)" [ngClass]="backgrounds[modalData.data.title]" class="relative overflow-y-auto rounded-sm bg-[#FCFAF8] bg-contain bg-center bg-no-repeat transition-opacity duration-700 ease-in-out">
        <div class="relative h-96 w-auto p-2 sm:w-96 sm:gap-2 sm:p-0">
          <button (click)="closeModal()" class="absolute right-3 top-2 z-50 text-gray-600 hover:text-gray-800">&#9587;</button>
          <!-- Modal content goes here -->
          <div class="py-14 text-center">
            <h3 [innerHTML]="modalData.data.title | safeHtml" class="no-underline"></h3>
            <p [innerHTML]="modalData.data.description | safeHtml" class="para-base py-8">></p>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
