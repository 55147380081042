import { Injectable, Signal, WritableSignal, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ButtercmsService } from '@core/services/butter-cms.service';
import { environment } from '@env/environment';
import Butter from 'buttercms';

export const butterService = Butter(environment.butterCmsAPIKey);

@Injectable({
  providedIn: 'root'
})
export class SignalService {

  private store: WritableSignal<any> = signal({});
  
  constructor(private butterCmsService: ButtercmsService) {}

  private updateStore(key: string, val: Signal<any>) {
    this.store.update(oldStore => ({
      ...oldStore,
      [key]: val
    }));
  }

  // ButterCms Actions

  getButterCmsContent(action: keyof ButtercmsService, apiKey: string) {
    !this.store()[apiKey] && this.updateStore(apiKey, toSignal(this.butterCmsService[action](apiKey)));
    return this.store()[apiKey];
  }
}
