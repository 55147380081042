import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

import { ModalComponent } from '@shared/components/modal/modal.component';

@Component({
  selector: 'ac-container',
  standalone: true,
  imports: [CommonModule, ModalComponent],
  templateUrl: './container.component.html'
})
export class ContainerComponent {
  @Input() useThemeClass!: string;
}
