import { Directive, ElementRef, HostListener, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[acResponsiveImg]',
  standalone: true
})
export class ResponsiveImgDirective implements OnInit {
  imgEl!: HTMLImageElement;
  src!: string;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    this.imgEl = this.el.nativeElement as HTMLImageElement;
    this.src = this.imgEl.getAttribute('src')!;
    this.setImgWidth();
  }

  setImgWidth() {
    const roundUpToNearest: number = 100;
    
    const originalWidth: number = this.imgEl.width == 0 ? parseInt(this.imgEl.getAttribute('width') as string) : this.imgEl.width;
    const width: number = Math.ceil(originalWidth / roundUpToNearest) * roundUpToNearest;

    const transformedUrl = this.src.replace('https://cdn.buttercms.com/', `https://cdn.buttercms.com/resize=width:${width}/`);
    this.renderer.setAttribute(this.el.nativeElement, 'src', transformedUrl);
  }
}
