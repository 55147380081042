<footer>
  <ac-container useThemeClass="bg-primary-900 py-8 text-white px-2 sm:py-5 md:px-8 md:py-7 lg:px-20 xl:px-52 bottom-0" *ngIf="footerDataModel$() as footerDataModel">
    <div class="grid grid-cols-12 gap-0">
      <div class="col-span-12 sm:col-span-9">
        <div class="grid grid-cols-11">
          <div class="col-span-12 sm:col-span-8 lg:col-span-4">
            <p class="cursor-pointer font-bold text-base" routerLink="/">Amplifi Capital (U.K.) Limited</p>
          </div>

          <div class="col-span-12 pt-8 sm:col-span-8 lg:col-span-7 lg:pt-0">
            <div class="grid grid-cols-4">
              <div class="col-span-2">
                <ul class="md-regular list-none">
                  <li *ngFor="let link of footerDataModel.ac_footer[0].menu_links" class="py-1">
                    <a [routerLink]="link.url" class="hover:underline">{{ link.label }}</a>
                  </li>
                </ul>
              </div>

              <div class="col-span-2">
                <ul class="md-regular list-none">
                  <li *ngFor="let link of footerDataModel.ac_footer[0].legal_links" class="py-1">
                    <a [routerLink]="link.url" class="hover:underline">{{ link.label }}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-span-12 my-4 inline-block border-l-0 pl-0 text-white sm:col-span-3 sm:my-0 sm:border-l-2 sm:pl-4">
        <div class="md-regular list-none">
          <div class="mb-4" [innerHtml]="footerDataModel.ac_footer[0].address | safeHtml"></div>
          <a href="https://www.linkedin.com/company/amplifi-capital-u-k-ltd/" target="_blank">
            <img ngSrc="assets/icons/linkedIn.webp" class="inline-block" height="45" width="45" alt="Linkedin Icon" priority="false" />
          </a>
        </div>
      </div>
    </div>

    <div class="sm-regular col-span-12 border-t-2 pt-4 child:mb-5 sm:border-t-0 sm:pt-8" [innerHtml]="footerDataModel.ac_footer[0].copy_right_content | safeHtml"></div>
  </ac-container>
</footer>
