import { Component } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';

import { ContainerComponent } from "../container/container.component";
import { SafeHtmlPipe } from "../../pipes/safe-html.pipe";

@Component({
  selector: 'ac-internet-status',
  standalone: true,
  templateUrl: './internet-status.component.html',
  imports: [CommonModule, ContainerComponent, NgOptimizedImage, SafeHtmlPipe]
})
export class InternetStatusComponent {
  isOnline = true;
  imageArr: HTMLImageElement[] = [];
  preloadImgArr = ['assets/icons/linkedIn.webp', 'https://cdn.buttercms.com/5pS7JcjyRLypeQqCSFLR']

  constructor() {
    this.preloadImages();
    this.checkInternetConnection();
    window.addEventListener('online', () => this.checkInternetConnection());
    window.addEventListener('offline', () => this.checkInternetConnection());
  }

  private checkInternetConnection(): void {
    this.isOnline = navigator.onLine;
    !this.isOnline && this.scrollToTop();
  }

  private preloadImages() {
    this.preloadImgArr.forEach((imageUrl) => {
      let img = new Image();
      img.src = imageUrl;
      this.imageArr.push(img);
    });
  }


  private scrollToTop() {
    window.scroll({
      top: 0,
      behavior: 'smooth' // Optional smooth scrolling
    });
  }
}
