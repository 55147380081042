<div [ngClass]="hamburgerStatus ? 'h-screen overflow-hidden' : ''">
  <ac-header (handleHamburgerClick)="onHamburgerClick($event)"></ac-header>

  <main class="mt-10 md:mt-0">
    <ac-internet-status>
      <router-outlet />
    </ac-internet-status>
  </main>

  <ac-footer *acLoadOnScroll></ac-footer>
</div>