import { Component, Renderer2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { SeoService } from '@shared/services/seo/seo.service';

@Component({
  selector: 'ac-content-container',
  templateUrl: './content-container.component.html'
})
export class ContentContainerComponent {
  hamburgerStatus = false;

  constructor(private router: Router, private seoService: SeoService, private renderer: Renderer2) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.seoService.setSeoTags(event.urlAfterRedirects);
      }
    });
  }

  onHamburgerClick(data: boolean) {
    this.hamburgerStatus = data;
  }
}
