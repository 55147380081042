import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface PorterData {
  data: any,
  state: boolean,
  component: string
}

@Injectable({
  providedIn: 'root'
})
export class PorterService {
  private showModalSubject = new BehaviorSubject<PorterData>({ data: '', state: false, component: '' });
  showModal$ = this.showModalSubject.asObservable();

  openModal(component: string, data?: any) {
    this.showModalSubject.next({ data, state: true, component });
  }

  closeModal() {
    this.showModalSubject.next({ data: '', state: false, component: '' });
  }
}
