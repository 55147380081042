export enum AcRoutingLink {
  Home = '',
  Team = 'team',
  About = 'about',
  CreditUnions = 'credit-unions',
  Investors = 'our-services-for-investors',
  ContactUs = 'contact',
  Careers = 'careers',
  Guides = 'guides',
  ThankYouPage = 'thank-you',

  // Below 3 routes is slug if we add ac-{TermsAndConditions || CookiePolicy || PrivacyPolicy} so that we can fetch data
  TermsAndConditions = 'terms-conditions',
  CookiePolicy = 'cookie-policy',
  PrivacyPolicy = 'privacy-policy',
}

export enum AcPorterComponent {
  Loader = 'loader',
  TeamsModal = 'teamsModal',
  OurValuesModal = 'ourValuesModal'
}