import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Observable } from 'rxjs';

import { ButterCMSConstants } from '@core/constants/butter-cms.constant';
import { ButterCMSModel } from '@core/modals/butter-cms.model';
import { ButtercmsService } from '@core/services/butter-cms.service';

@Injectable({
  providedIn: 'root'
})
export class SeoService {
  seoPageData$!: Observable<ButterCMSModel.SEOData>;
  seoRepeaterData: ButterCMSModel.SEO[] = [];

  constructor(private titleService: Title, private meta: Meta, @Inject(DOCUMENT) private document: Document, private butterCMSService: ButtercmsService) {
    this.seoPageData$ = this.butterCMSService.getPageContent(ButterCMSConstants.SEO_METADATA);
  }

  setSeoTags(url: string) {
    this.seoPageData$.subscribe((data) => {
      this.seoRepeaterData = data.fields.seo_data.seo_repeater;
      const { seo_title, seo_description, seo_url } = this.seoRepeaterData.find((data: ButterCMSModel.SEO) => data.seo_url === url) || {};

      if (seo_title && seo_description && seo_url) {
        this.setAndUpdateSEOTags(seo_title, seo_description);
      }

      this.setCanonical();
    });
  }

  // Set SEO Tags
  setAndUpdateSEOTags(seo_title: string, seo_description: string) {
    this.meta.updateTag({
      property: 'og:site_name',
      content: 'Amplifi Capital'
    });
    this.meta.updateTag({ property: 'og:title', content: seo_title });
    this.meta.updateTag({ name: 'description', content: seo_description });

    // Set regular title and description meta tags
    this.titleService.setTitle(seo_title);
  }

  setCanonical(url?: string) {
    const canURL = (url == undefined ? this.document.URL : url).split('?')[0];
    const existingLink = this.document.querySelector('link[rel="canonical"]') as HTMLLinkElement;
    if (existingLink) {
      existingLink.setAttribute('href', canURL);
      this.meta.updateTag({ property: 'og:url', content: canURL });
    } else {
      const link: HTMLLinkElement = this.document.createElement('link');
      link.setAttribute('rel', 'canonical');
      link.setAttribute('href', canURL);
      this.document.head.appendChild(link);
      this.meta.updateTag({ property: 'og:url', content: canURL });
    }
  }
}
