import { Component, EventEmitter, Output, Signal } from '@angular/core';

import { ButterCMSModel } from '@core/modals/butter-cms.model';
import { ButterCMSConstants } from '@core/constants/butter-cms.constant';
import { SignalService } from '@shared/services/signal/signal.service';

@Component({
  selector: 'ac-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent {
  @Output() handleHamburgerClick = new EventEmitter<boolean>();
  menuItems$!: Signal<ButterCMSModel.NavigationMenuItems[]>;
  showHamburger = false;

  constructor(private signalService: SignalService) {
    this.menuItems$ = this.signalService.getButterCmsContent('getNavigationMenuItem', ButterCMSConstants.NAVIGATION_MENU_ITEMS_API);
  }

  closeMenu() {
    this.showHamburger = false;
    this.handleHamburgerClick.emit(false);
  }

  toggleHamburger() {
    this.showHamburger = !this.showHamburger;
    this.handleHamburgerClick.emit(this.showHamburger);
  }
}
