export class ButterCMSConstants {
  /**
   * Header component
   */
  static readonly NAVIGATION_MENU_ITEMS_API = 'navigation_menu';
  static readonly FOOTER_PAGE_API = 'ac_footer';
  static readonly CREDIT_UNIONS_PAGE_API = 'ac-credit-union-page';
  static readonly CAREERS_PAGE_API = 'ac-career-page';
  static readonly INVESTORS_PAGE_API = 'ac-investor-page';
  static readonly CONTACT_US_PAGE_API = 'ac-contact-us-page';
  static readonly ABOUT_PAGE_API = 'ac-about-page';
  static readonly TEAM_PAGE_API = 'ac-team-page';
  static readonly SEO_METADATA = 'ac-seo-metadata';
  static readonly HOME_PAGE = 'ac-home-page';
}
