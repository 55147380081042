import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { AppRoutingModule } from '@routing/app-routing.module';
import { PorterComponent } from "./shared/components/porter/porter.component";

@NgModule({
    declarations: [AppComponent],
    bootstrap: [AppComponent],
    imports: [BrowserModule.withServerTransition({ appId: 'serverApp' }), AppRoutingModule, RouterModule, PorterComponent]
})
export class AppModule {}
