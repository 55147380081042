import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ContentContainerComponent } from '@features/components/content-container/content-container.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/',
    pathMatch: 'full',
  },
  {
    path: '',
    component: ContentContainerComponent,
    loadChildren: () =>
      import('../features/features.module').then(
        (feature) => feature.FeaturesModule
      ),
  },
  {
    path: '**',
    redirectTo: '/',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking',
    scrollPositionRestoration: 'top',
}),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
