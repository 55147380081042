import { Component, Signal } from '@angular/core';

import { ButterCMSConstants } from '@core/constants/butter-cms.constant';
import { ButterCMSModel } from '@core/modals/butter-cms.model';
import { SignalService } from '@shared/services/signal/signal.service';

@Component({
  selector: 'ac-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent {
  footerDataModel$: Signal<ButterCMSModel.FooterComponentModel>;

  constructor(private signalService: SignalService) {
    this.footerDataModel$ = this.signalService.getButterCmsContent('getCollectionContent', ButterCMSConstants.FOOTER_PAGE_API);
  }

}