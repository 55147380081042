<header class="fixed bottom-auto left-0 right-0 top-0 z-30 mx-auto max-w-5xl border-b border-gray-300 bg-white px-5 py-3 sm:px-4 sm:py-5 md:relative md:left-auto md:right-auto md:top-auto md:z-0 md:border-0 md:py-7 lg:px-6 xl:px-0">
  <nav class="grid grid-cols-12">
    <div class="col-span-10 md:col-span-3">
      <img (click)="closeMenu()" alt="Amplifi Capital logo" class="sm:h-13 h-8 max-w-fit cursor-pointer md:h-14" height="80" ngSrc="https://cdn.buttercms.com/tqqGs4EsQiDFz9SK8k6L" acResponsiveImg priority routerLink="/" width="277" />
    </div>
    <div class="z-50 col-span-2 my-auto place-self-end bg-white md:col-span-9">
      <ul class="hidden items-center md:flex md:space-x-4 lg:space-x-10">
        <ng-container *ngFor="let menuItem of menuItems$()">
          <li [routerLinkActive]="'underline text-primary-900'" class="nav-link">
            <a [routerLink]="menuItem.url">
              {{ menuItem.label }}
            </a>
          </li>
        </ng-container>
      </ul>

      <!-- Mobile hamburger menu icon -->
      <div (click)="toggleHamburger()" class="my-auto mt-0.5 h-2.5 cursor-pointer md:hidden">
        <div class="relative mx-auto w-6 rotate-0 transition-all duration-500 ease-in-out md:hidden">
          <div [ngClass]="showHamburger ? 'top-1.5 rotate-[135deg]' : 'top-0 rotate-0'" class="absolute block h-0.5 w-full bg-neutral-600 transition-all duration-700 ease-in-out"></div>
          <div [ngClass]="showHamburger ? 'hidden' : 'top-1.5 rotate-0'" class="absolute block h-0.5 w-full bg-neutral-600 transition-all duration-700 ease-in-out"></div>
          <div [ngClass]="showHamburger ? 'top-1.5 -rotate-[135deg]' : 'top-3 rotate-0'" class="absolute block h-0.5 w-full bg-neutral-600 transition-all duration-700 ease-in-out"></div>
        </div>
      </div>
    </div>
    <div class="z-10 col-span-12">
      <div class="flex w-full justify-center md:hidden">
        <div [ngClass]="showHamburger ? 'active left-0 top-[3.56rem] block' : '-left-[200%]'" class="absolute z-50 w-full transition-all duration-500" id="mobile-menu">
          <!-- add hidden here later -->
          <ul class="h-screen bg-white">
            <ng-container *ngFor="let menuItem of menuItems$()">
              <li class="nav-link py-3">
                <a (click)="toggleHamburger()" [routerLinkActive]="'underline text-primary-900'" [routerLink]="menuItem.url" class="block pl-3">
                  {{ menuItem.label }}
                </a>
              </li>
            </ng-container>
          </ul>
        </div>
      </div>
    </div>
  </nav>
</header>
