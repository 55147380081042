import { Component } from '@angular/core';
import { ModalComponent } from '../modal/modal.component';
import { LoaderComponent } from '../loader/loader.component';
import { CommonModule } from '@angular/common';
import { PorterData, PorterService } from '@shared/services/porter/porter.service';
import { AcPorterComponent } from '@routing/ac-routing.enum';

@Component({
  selector: 'ac-porter',
  standalone: true,
  templateUrl: './porter.component.html',
  imports: [CommonModule, ModalComponent, LoaderComponent]
})
export class PorterComponent {
  handlerData!: PorterData;
  porterEnum = AcPorterComponent;
  
  constructor(private porterService: PorterService){
    this.porterService.showModal$.subscribe((showModal: PorterData) => {
      this.handlerData = showModal;
    })
  }
}
