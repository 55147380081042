import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, Input, OnInit, inject } from '@angular/core';
import { ResponsiveImgDirective } from '@shared/directives/responsive-img/responsive-img.directive';

import { SafeHtmlPipe } from '@shared/pipes/safe-html.pipe';
import { PorterService } from '@shared/services/porter/porter.service';

@Component({
  selector: 'ac-modal',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage, SafeHtmlPipe, ResponsiveImgDirective],
  templateUrl: './modal.component.html'
})
export class ModalComponent {
  @Input() modalData!: any;

  porterService = inject(PorterService);

  backgrounds: Record<string, string> = {
    'Take Ownership': 'bg-our-values-ownership',
    'Be Bold': 'bg-our-values-be-bold',
    'Work Smart': 'bg-our-values-work-smart',
    'Work Together': 'bg-our-values-work-together'
  };

  closeModal() {
    this.porterService.closeModal();
  }

  preventPropagation(event: MouseEvent) {
    event.stopPropagation();
  }
}
