<ng-container *ngIf="isOnline else offline">
    <ng-content />
</ng-container>
<ng-template #offline>
    <ac-container useThemeClass="ac-cpm">
        <div class="grid grid-cols-12 gap-4 lg:gap-8">
            <div class="col-span-12 md:col-span-6 my-auto">
                <h1 class="display-2 mb-6">Oops! No connection detected.</h1>
                <p class="lg-regular py-4">Hey there! It seems your device is off the grid. Take a breather and
                    enjoy
                    this unexpected digital downtime. When ready, try getting back online or refresh this page.</p>
            </div>
            <div class="col-span-12 place-self-center md:col-span-6">
                <img class="object-cover" [src]="preloadImgArr[1]" alt="Technical Problem" height="120" width="120" />
            </div>
        </div>
    </ac-container>
</ng-template>